// --------------------------------------------------------------------
// Author Name   : Nirav Joshi
// --------------------------------------------------------------------
// For more info : https://www.wrappixel.com/
// --------------------------------------------------------------------
// Product Name  : Adminpro Admin
// --------------------------------------------------------------------

// (Required file)
@import "bootstrap/functions";

@import "config/variables";
@import "config/custom-variables";


// dark theme
@import "custom/structure/dark/dark-theme";

// Import Bootstrap source files (Required file)
@import "bootstrap/bootstrap";

// Extensions
@import "custom/extensions/extensions";

// Mixins
@import "custom/mixins/mixins";




// Structure
@import "custom/structure/layout";
@import "custom/structure/preloader";
@import "custom/structure/navbar";
@import "custom/structure/sidebar";
@import "custom/structure/customizer";
@import "custom/structure/margin";
@import "custom/structure/padding";


// Settings
@import "custom/structure/horizontal/horizontal";
@import "custom/structure/rtl/rtl";
@import "custom/structure/rtl/horizontal-rtl";
@import "custom/structure/stylish-menu/stylish-menu-sidebar";
@import "custom/structure/stylish-menu/stylish-menu-layout";
@import "custom/structure/stylish-menu/stylish-menu-colors";
@import "custom/structure/rtl/stylish-rtl";

// Theme Colors

@import "custom/theme-colors/theme-colors";

// Components
@import "custom/components/alert";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/checkbox";
@import "custom/components/card";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/range";
@import "custom/components/reboot";
@import "custom/components/progress";
@import "custom/components/label";

// Pages
@import "custom/pages/dashboards/dashboard1";
@import "custom/pages/dashboards/dashboard2";
@import "custom/pages/dashboards/dashboard3";
@import "custom/pages/dashboards/dashboard4";
@import "custom/pages/authentication";
@import "custom/pages/emails";
@import "custom/pages/form-pages";
@import "custom/pages/gallery-cards";
@import "custom/pages/google-vector";
@import "custom/pages/inner-pages";
@import "custom/pages/nestable";
@import "custom/pages/taskboard";
@import "custom/pages/timeline";
@import "custom/pages/typeahead";

// Plugins
@import "custom/plugins/animation";
@import "custom/plugins/charts";
@import "custom/plugins/forms";
@import "custom/plugins/icons";
@import "custom/plugins/perfect-scrollbar";
@import "custom/plugins/tables";

// Widgets
@import "custom/widgets/chat-popup";
@import "custom/widgets/chat-widgets";
@import "custom/widgets/comments";
@import "custom/widgets/custom-check-radio-switch";
@import "custom/widgets/feeds";
@import "custom/widgets/profile";
@import "custom/widgets/steamline";
@import "custom/widgets/task";
@import "custom/widgets/topbar-mail";

//This is for the icons (Required file)

@import "../icons/font-awesome/css/fontawesome-all.css";
@import "../icons/simple-line-icons/css/simple-line-icons.css";
//@import "../icons/weather-icons/css/weather-icons.min.css";
@import "../icons/themify-icons/themify-icons.css";
@import "../icons/flag-icon-css/flag-icon.min.css";
@import "../icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "../icons/crypto-icons/cryptocoins.css";

.float-right {
  float: right;
}
.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.page-wrapper {
  overflow: scroll;
  height: 100%;
}