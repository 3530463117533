/*******************
 Breadcrumb and page title
*******************/

.page-titles {
    margin-bottom: 20px;
    .text-themecolor {
        color: $info;
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: transparent;
        font-size: 14px;
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: themify;
            color: $gray-400;
            font-size: 11px;
        }
    }
}

.breadcrumb {
    &.bg-light-info,
    &.border-info {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $info;
        }
    }
}

.breadcrumb {
    &.bg-light-primary,
    &.border-primary {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $primary;
        }
    }
}

.breadcrumb {
    &.bg-light-warning,
    &.border-warning {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $warning;
        }
    }
}

.breadcrumb {
    &.bg-light-danger,
    &.border-danger {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $danger;
        }
    }
}

.breadcrumb {
    &.bg-light-success,
    &.border-success {
        .breadcrumb-item+.breadcrumb-item::before {
            color: $success;
        }
    }
}