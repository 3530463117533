// 
// Sales overview chart
// 
#sales-overview{
    .ct-point{
        stroke-width:6px;
    }
    .ct-grid{
        stroke-dasharray:0px;
        stroke: rgba(0,0,0,.1);
    }
    .ct-area{
        fill-opacity:1;
    }
    .ct-series-a .ct-area, .ct-series-a .ct-point{
        fill: $primary;
        stroke: $primary;
    }
    .ct-series-b .ct-area, .ct-series-b .ct-point{
        fill: $info;
        stroke: $info;
    }
    .ct-series-c .ct-area, .ct-series-c .ct-point{
        fill: $light;
        stroke: $light;
    }
}

.ct-grid{
    stroke: rgba(0,0,0,.1);
}

.activity-box {
    .date-divider {
        border-top: 2px solid $border-color;
        span {
            top: -15px;
            padding: 5px 15px;
        }
    }
    .image-list{
        > a{
            margin-left:-15px;            
            &:first-child, &:last-child{
                margin-left:0px;
            }
            &:hover{
                z-index:10;
                img{
                    box-shadow:0 2px 15px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.social-icons {
    a {
        color: $gray-500;
        &:hover {
            color: $gray-800;
        }
    }
}

#visitor {
    .c3-chart-arcs-title {
        font-size: 26px;
        font-weight: 600;
        fill: $black !important;
        font-family: $font-family-sans-serif;
    }
}

.website-visitor {
    .ct-series-a .ct-area {
        fill-opacity: 0.2;
        fill: url(#gradient); 
    }
    .ct-series-a .ct-line, .ct-series-a .ct-point {
        stroke: $success;
        stroke-width: 2px; 
    }
    .ct-series-b .ct-area {
        fill: $info;
        fill-opacity: 0.1; 
    }
    .ct-series-b .ct-line, .ct-series-b .ct-point {
        stroke: $info;
        stroke-width: 2px; 
    }
    .ct-series-a .ct-point, .ct-series-b .ct-point {
        stroke-width: 6px; 
    }
} 

.btn-outline-secondary {
    border-color: #cccccc;
}

.msg-widget {
    .msg-item {
        .msg-body {
            .down-arrow {
                width: 0;
                position: absolute;
                bottom: -8px;
                height: 0;
                left: 10px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid $white;
            }
        }
    }
}

// 
// Blog image responsive
// 
@include media-breakpoint-up(lg) {
    .blog-img1 {
        max-height: 309px;
    }
}