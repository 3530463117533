// #########################################
// Height-width
// #########################################
%height_width_auto {
	height: auto;
	width: auto;
}

%height_width_100 {
	height: 100%;
	width: 100%;
}

// #########################################
// Text wrappping
// #########################################
%text_wrapping {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// #########################################
// Fonts
// #########################################
%font_210 {
	font-size: 210px;
	font-weight: 900;
    text-shadow: 4px 4px 0 $white, 6px 6px 0 $dark;
    line-height: 210px;
	@include media-breakpoint-only(md) {
		font-size: 150px;
		line-height: 150px;
	}
	@include media-breakpoint-only(sm) {
		font-size: 70px;
		line-height: 70px;
	}
}

// #########################################
// Sidebar arrow transform
// #########################################
%sidebar_arrow_transform {
	-webkit-transform: rotate(135deg) translateY(-50%);
    -ms-transform: rotate(135deg) translateY(-50%);
    -o-transform: rotate(135deg) translateY(-50%);
    transform: rotate(135deg) translateY(-50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

%sidebar_arrow_active_transform {
	-webkit-transform: rotate(-135deg) translateY(-50%);
    -ms-transform: rotate(-135deg) translateY(-50%);
    -o-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
}

// #########################################
// Blowing Circles
// #########################################
%test {
	@-webkit-keyframes blow {
        0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
        50% {
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
        100% {
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
            opacity: 0;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
    }
    @keyframes blow {
        0% {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
        50% {
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
            opacity: 1;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
        100% {
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
            opacity: 0;
            -webkit-transform: scale3d(1, 1, 0.5);
            transform: scale3d(1, 1, 0.5);
        }
    }
}

// #########################################
// Common
// #########################################
%square {
    border-radius: 0px
}

%rotate45 {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
}