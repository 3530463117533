// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.label {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  @include font-size($badge-font-size);
  font-weight: $badge-font-weight;
  line-height: 1;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  @include gradient-bg();

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .label {
  position: relative;
  top: -1px;
}

.label-primary {
  background-color: rgba($color: $primary, $alpha: 1) !important;
  border-color: rgba($color: $primary, $alpha: 1) !important;
}

.label-danger {
  background-color: rgba($color: $danger, $alpha: 1) !important;
  border-color: rgba($color: $danger, $alpha: 1) !important;
}

.label-warning {
  background-color: rgba($color: $warning, $alpha: 1) !important;
  border-color: rgba($color: $warning, $alpha: 1) !important;
}

.label-success {
  background-color: rgba($color: $success, $alpha: 1) !important;
  border-color: rgba($color: $success, $alpha: 1) !important;
}

.label-inverse {
  background-color: rgba($color: $inverse, $alpha: 1) !important;
  border-color: rgba($color: $inverse, $alpha: 1) !important;
}

.label-default {
  border-color: rgba($color: $inverse, $alpha: 1) !important;
  border-width: 1px;
  border-style: solid;
  color: $inverse;
}