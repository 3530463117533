.profiletimeline {
  position: relative;
  padding-left: 40px;
  margin: 10px 10px 0 30px;
  border-left: 1px solid $border-color;
  .sl-left {
    display: flex;
    margin-left: -60px;
    z-index: 1;
    margin-right: 15px;
    img {
      max-width: 40px;
    }
  }
}

.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px;
}

.profiletimeline .sl-date {
  font-size: 12px;
  color: $text-muted;
}
