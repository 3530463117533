// Checkbox
.form-check {
    padding-left: 0;
    padding-right: 1.75em;
    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: -1.75em;
    }
}

.form-check-inline {
    margin-left: 1rem;
    margin-right: 0;
}

// Breadcrumb
.breadcrumb-item+.breadcrumb-item::before {
	float: right;
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: 0;
	padding-right: .5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
	padding-left: .5rem;
	padding-right: 0;	
}

// Form Select
.form-select {
	padding: .375rem .75rem .375rem 1.75rem;
	background-position: left .75rem center;
}

// Input groups
.input-group {
    > .form-control,
    > .custom-select {
        &:not(:last-child) { @include border-start-radius(0); }
        &:not(:first-child) { @include border-end-radius(0); }
    }

    // Custom file inputs have more complex markup, thus requiring different
  // border-radius overrides.
  > .custom-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .custom-file-label,
    &:not(:last-child) .custom-file-label::after { @include border-start-radius(0); }
    &:not(:first-child) .custom-file-label { @include border-end-radius(0); }
  }
  $validation-messages: "";
  > :not(:first-child):not(.dropdown-menu)#{$validation-messages} {
    margin-right: -$input-border-width;
    margin-left: 0;
    @include border-start-radius(0);
  }
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child)  {
  @include border-end-radius(0.25rem);
  @include border-start-radius(0);
}

 .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
 .input-group > .input-group-prepend:not(:first-child) > .btn {
     @include border-end-radius(0);
 }

 .input-group .form-control:not(:first-child),
 .input-group .form-control:not(:last-child){
     border-radius: 0 !important;
 }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  @include border-start-radius(0.25rem);
  @include border-end-radius(0);
}

.form-floating>label {
	right: 0;
	left: auto;
}

.form-switch {
	.form-check-input {
		margin-left: 8px;
	}
}

// Accordian
.accordion-button::after {
	margin-left: unset;
	margin-right: auto;
}

// Modal
.modal-header .btn-close {
	margin: -.5rem  auto -.5rem -.5rem
}

// Alert

.alert-dismissible.customize-alert {
  padding-left: 25px !important;
}

.alert-dismissible .btn-close {
	left: 0;
	right: auto;
}

.alert.customize-alert .side-line {
  left: unset;
  right: 0;
}

// Toast
.toast-header .btn-close {
	margin-left: -.375rem;
    margin-right: .75rem;
}

.toast {
	.btn-close.ms-auto {
		margin-left: 8px !important;
	}
}

// Dropdown
.dropdown-toggle::after {
	margin-right: .255em;
	margin-left: 0;
}