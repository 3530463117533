/*******************
Paddings
*******************/

html body {
  .p-0 {
    padding: 0px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-30 {
    padding: 30px;
  }
  .p-l-0 {
    padding-left: 0px;
  }
  .p-l-10 {
    padding-left: 10px;
  }
  .p-l-20 {
    padding-left: 20px;
  }
  .p-r-0 {
    padding-right: 0px;
  }
  .p-r-10 {
    padding-right: 10px;
  }
  .p-r-20 {
    padding-right: 20px;
  }
  .p-r-30 {
    padding-right: 30px;
  }
  .p-r-40 {
    padding-right: 40px;
  }
  .p-t-0 {
    padding-top: 0px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-0 {
    padding-bottom: 0px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
  .p-b-40 {
    padding-bottom: 40px;
  }
}